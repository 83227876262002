import React from "react"

import {
  HomeSection,
  SEO,
  Header,
  Footer,
  OptionsSection,
  AppSection,
  PartneringSection,
  CallUsSection,
} from "../components"

const IndexPage = () => (
  <>
    <SEO title="Medical Bureau" />
    <Header />
    <HomeSection />
    <OptionsSection />
    <AppSection />
    <PartneringSection />
    <CallUsSection />
    <Footer />
  </>
)

export default IndexPage
